.c-amenities {
    .c-amenities__left {
        .o-col__inner {
            display: block;
            margin-left: auto;
            margin-right: 0px;
            width: 100%;
            height: 300px;
            @include media(md) {
                width: 100%;
                height: 450px;
            }
            @include media(lg) {
                width: 600px;
                height: 450px;
            }
        }
    }
    .c-amenities__right {
        .o-col__inner {
            display: block;
            margin-top: 0px;
            margin-left: 0px;
            margin-right: auto;
            width: 100%;
            height: auto;
            background-color: color('red');
            padding: 30px;
            @include media(md) {
                margin-top: 30px;
                width: 110%;
                margin-left: -50px;
            }
            @include media(lg) {
                margin-top: 50px;
                width: 600px;
            }
            h4 {
                color: white;
            }
            p {
                color: white;
            }
            .c-btn {
                display: table;
                margin: 0px auto;
                padding: 0px 20px;
                padding-top: 5px;
            }
        }
    }
}
