h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
}

h1 {
    @include font-size('h1');
    font-family: $kalam_regular;
    letter-spacing: 1px;
    text-transform: uppercase;
    color:color('orange');
}

h2 {
    @include font-size('h2');
    font-family: $kalam_regular;
    letter-spacing: 1px;
    text-transform: uppercase;
    color:color('orange');
}

h3 {
    @include font-size('h3');
    font-family: $kalam_regular;
    letter-spacing: 1px;
    text-transform: uppercase;
    color:color('red');
}

h4 {
    @include font-size('h4');
    font-family: $kalam_regular;
    letter-spacing: 1px;
    color:color('red');
}

h5 {
    @include font-size('h5');
    font-family: $hind_bold;
    letter-spacing: 1px;
    color:color('orange');
}

h6 {
    @include font-size('h6');
    font-family: $hind_bold;
    letter-spacing: 1px;
    color:color('red');
}

p {
    @include font-size('p');
    font-family: $hind_light;
    line-height: 30px;
    color:color('brown');
    padding-bottom: 15px;
}

a {
    @include font-size('p');
    font-family: $hind_bold;
    text-decoration: none;
}
