input,
select,
textarea {
    width: 100%;
    padding: rem(10px);
    border: 1px solid color('red');
    border-radius: 0;
    font-family: $hind_light !important;
    color: color('red');
    font-size: 18px !important;
}

input[type="submit"] {
    width: auto;
}


// Form CSS

.gform_wrapper {
    .gsection {
        margin-bottom:-30px;
    }
    .gfield_label {
        color: color('orange');
        font-size: 22px !important;
        font-family: $hind_bold;
    }
    label {
        color: color('orange');
        font-family: $hind_bold;
    }
    input {
        border-color: color('red');
        height: 40px;
        margin-bottom: 0px;
    }
    .gform_button {
        display: table !important;
        margin: 0px auto !important;
        padding: rem(0px) rem(10px);
        color: white;
        border: none;
        text-decoration: none;
        font-family: $hind_bold;
        background-color: color('red');
        &:hover {
            background-color: color('orange');
        }
        a {
            font-size: 24px;
            color: color('white');
            text-decoration: none;
            &:hover {
                color: color('white');
            }
        }
    }
    select, option {
        border-color: color('red');
        height: 40px;
        color: color('blue');
    }
    textarea, .gfield {
        border-color: color('red');
    }

}
