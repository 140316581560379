span {
    font-size: 28px;
    font-family: $hind_bold;
}
strong {
    font-family: $hind_bold;
}
.c-content--main {
    padding: 40px 0px;
    padding-bottom: 60px;
    h6 {
        padding-bottom: 50px;
    }
    h5 {
        padding-bottom: 15px;
    }
    a {
        font-size: inherit;
        color: inherit;
    }
    p {
        font-weight: bold;
    }
    .c-btn {
        color: white;
        margin-top: 15px;
        display: table;
        margin: 0px auto;
    }
}
.c-content__header {
    h1 {
        padding-bottom: 30px;
    }
}


/// Secondary Glider Rides Page

.page-id-26 {
    .gallery {
        .gallery-item{
            display: inline-block;
            padding: 0px 5px;
            overflow: hidden;
            margin-bottom: 15px;
            width: 50%;
            max-height: 90px;
            @include media(xs) {
                max-height: 170px;
            }
            @include media(md) {
                width: 25%;
                max-height: 150px;
            }
            @include media(lg) {
                max-height: 180px;
            }
            img {
                display: block;
                width: auto;
                margin-bottom: 15px;
                opacity: 1;
                -webkit-transition: -webkit-transform 1.5s ease-in-out;
                transition:transform 1.5s ease-in-out;
                height: auto;
                @include media(xs) {
                }
                @include media(sm) {
                }
                @include media(md) {
                }
                &:hover {
                    opacity: .7;
                }
            }
        }
    }
}


//park map
.page-id-24 {
    .c-content--main {
        img,.c-btn {
            display: table;
            margin: 0px auto;
        }
    }
}
