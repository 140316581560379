// styles for listed items
.c-list {
    list-style: none;
    padding-left: rem(40px);
    margin-bottom: 15px;
}
.c-list li  {
    @include font-size('p');
    color:color('brown');
    line-height: 38px;
    margin-bottom: 15px;
    font-family: $hind_light;
    font-weight: bold;
    p, a {
        font-weight: bold;
        font-size: rem(30px);
        font-family: $hind_light;
        @include media(sm) {
            font-size: rem(26px);
        }
    }
}
.c-list li:before {
    content: "•";
    color:color('red');
    position: absolute;
    margin-left: neg(rem(15px));
    margin-top: rem(0px);
}
