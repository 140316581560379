.c-attractions {
    .c-attractions__left {
        .o-col__inner {
            display: block;
            margin-top: 0px;
            margin-left: auto;
            margin-right: 0px;
            width: 100%;
            height: auto;
            background-color: color('orange');
            padding: 30px;
            position: relative;
            z-index: 6;
            @include media(md) {
                margin-top: 40px;
                width: 105%;
                margin-right: -20px;
            }
            @include media(lg) {
                margin-top: 50px;
                width: 600px;
            }
            h4 {
                color: white;
            }
            p {
                color: white;
            }
            .c-btn {
                display: table;
                margin: 0px auto;
                padding: 0px 20px;
                padding-top: 5px;
            }
        }
    }
    .c-attractions__right {
        .o-col__inner {
            display: block;
            margin-left: 0px;
            margin-right: auto;
            width: 100%;
            height: 300px;
            position: relative;
            z-index: 5;
            @include media(md) {
                margin-left: -30px;
                width: 110%;
                height: 450px;
            }
            @include media(lg) {
                width: 600px;
                height: 450px;
            }
        }
    }
}
