.c-header {
    background-color: color('beige');
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    padding-top: 30px;
    .c-home__phone {
        .c-btn {
            left:auto;
            right:auto;
            display: table;
            margin: 0px auto;
            position: relative;
            font-size: 30px;
            padding: 0px 10px;
            padding-top: 5px;
            @include media(md) {
                display: none;
            }
        }
        a {
            font-size: 36px;
            color: white;
            text-align: left;
            display: none;
            @include media(md) {
                display: block;
                color: color('red');
            }
        }
    }
    .c-btn {
        position: absolute;
        right:15px;
        font-size: 30px;
        display: none;
        color: white;
        @include media(md) {
            display: block;
        }
    }
}
