.c-welcome {
    padding: 20px 0px;
    background-image: url(/app/themes/sage/dist/images/welcome_background.jpg);
    background-size: cover;
    background-position: center;
    @include media(md) {
        padding: 60px 0px;
    }
    span {
        font-size: 26px;
        @include media(md) {
            font-size: 30px;
        }
    }
    p {
        font-size: 22px;
        color: white;
        @include media(md) {
            font-size: 26px;
        }
    }
    h1 {
        color: white;
        text-align: center;
        padding-bottom: 15px;
        line-height: 46px;
        @include media(md) {
            padding-bottom: 30px;
            line-height: 60px;

        }
    }
}
