// Allows full width within template sections

.page-template-template-landing-page-php,
.page-template-template-home-for-sale {
    .c-content--main {
        padding: 0px;
        .c-container__control {
            padding: 0px;
            max-width: 100%;
            .c-welcome__landing {
                padding: 60px 0px;
                background-image: url(/app/themes/sage/dist/images/welcome_background.jpg);
                background-size: cover;
                background-position: center;
                padding-bottom: 80px;
            }
        }
    }
}
// Welcome section

// Left side
.c-landing__content {
    h1,h2,h3,h4,h5 {
        color: white;
        font-size: 45px;
    }
    p {
        color: white;
        strong {
            font-size: inherit;
            color: color('orange');
        }
    }
}
// Right side
.c-landing__form {
    .gform_button {
        padding: 0px 15px;
        padding-top: 5px;
        line-height: 0px;
        margin-left: 0px !important;
        color: color('blue');
        text-decoration: none;
        font-size: rem(36px);
        font-family: $hind_bold;
        transition: background-color 1s ease;
        letter-spacing: 1px;
        color: white;
        border: 4px solid white;
        background-color: color('orange');
        &:hover {
            background-color: color('orange', 'medium');
        }
        @include media(sm) {
            font-size: rem(13px) !important;
        }
        @include media(md) {
            font-size: rem(18px) !important;
        }
    }
    .datepicker  {
        width: 100% !important;
        font-family: $hind_light;
    }
    .gform_wrapper {
        font-family: $hind_light;
        position: relative;
        padding: 15px 30px;
        padding-bottom: 30px;
        background-color: color('grey');
        @include media(sm) {
            padding-bottom: 15px;
            margin-bottom: -200px;
        }
        &::before {
            content: '';
            width: 55px;
            height: 85px;
            position: absolute;
            left: 0px;
            top: -60px;
            background-image: url(/app/themes/sage/dist/images/yellow-arrow.png);
            background-position: center;
            background-size: cover;
            @include media(sm) {
                left: -90px;
                top: 0px;
                width: 81px;
                height: 125px;
            }
        }
        .gfield {
            padding: 0px !important;
            font-family: $hind_light;
        }
        .gfield_label {
          display: none!important;
        }
    }
    .gform_footer {
        padding:0px !important;
    }
    h1,h2,h3,h4,h5,h6 {
        position: relative;
        color: white;
        font-size: 40px;
        text-align: center;
        @include media(sm) {
            text-align: left;
            width: 600px;
            left: -150px;
            font-size: 30px;
        }
        @include media(md) {
            left: -100px;
        }
        @include media(lg) {
            left: -100px;
            font-size: 40px;
        }
    }

    .c-form__container {
        position: relative;
        margin-top: 0px;
        @include media(sm) {
        margin-top: -200px;
        }
        @include media(md) {
            margin-top: -250px;
        }
        @include media(lg) {
        margin-top: -300px;
        }
    }

}
.ui-datepicker {
    font-family: $hind_light;
    background-color: white;
    font-weight: bold;
    width: auto !important;
}
option {
    color: #000 !important;
}
.gform_wrapper textarea.medium {
    height: 100px !important;
    width: 100%;
}

.gfield_description.validation_message {
  display: none;
}

.gfield_error {
  margin: 0px;
  padding: 0px;
  line-height: 15px;
}
.gfield_required {
  display: none;
}
