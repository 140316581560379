.c-logo {
  display: block;
  width: auto;
  margin: 0px auto;
  margin-top: 20px;
  padding-top: 15px;
  @include media(md) {
    padding-top: 0px;
  }
}
