.c-testimonials {
    background: url('../../dist/images/testimonials.jpg') no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 40px;
    padding-bottom: 0px;
    footer {
        padding: 0px;
        background-color: rgba(0,0,0,0);
        background-image: none;
    }
    h1, p {
        text-align: center;
        color: white;
    }
    h1 {
        font-size: 30px;
        @include media(md) {
            font-size: rem(60px);
        }
    }

    h1:after {
        content:'';
        background: url('../../dist/images/quotes.png') no-repeat;
        display:block;
        background-size: contain;
        background-position: left;
        height: rem(25px);
        width: rem(30px);
        margin:20px auto;;
    }
    h2 {
        font-weight: lighter;
        font-size: rem(36px);
        text-transform: uppercase;
        padding-bottom: 20px;
        @include media(md) {
            font-size: rem(52px);
        }
    }
    p {
        font-family: $hind_light;
        font-size: 26px;
    }
    cite {
        font-family: $hind_light;
        text-align: center;
        display: block;
        color: white;
        margin-top: 20px;
    }
    // controll for the active dots
    .c-carousel {
        .owl-dot {
            display: inline-block;
            span {
                display: block;
                width: rem(17px);
                height: rem(17px);
                margin: .1875rem;
                background-color: white;
                border-radius: 100%;
                margin-bottom: 50px;
            }
        }
        .owl-dot.active  {
            span{
                background-color: color('red');
            }
        }
        .owl-dots {
            margin-top: 1.875rem;
            text-align: center;
        }

    }
}
