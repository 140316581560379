.c-home__navigation {
    background-color: color('red');
    margin-top: 40px;
    @include media(md) {
        margin-top: 0px;
    }
    .c-nav {
        display: none;
        @include media(md) {
            display: block;
        }
        ul {
            list-style: none;
            text-align: center;
            li {
                display: inline-block;
                padding: 10px 5px;
                @include media(lg) {
                    padding: 10px 10px;
                }
                a {
                    color: white;
                    font-size: 16px;
                    font-family: $hind_bold;
                    text-transform: uppercase;
                    &:hover {
                        color: color('orange');
                    }
                    @include media(lg) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
.active a, .current-menu-item {
    border-bottom: 2px solid white;
}
.c-footer {
    .active a, .current-menu-item {
        border-bottom: none;
    }
}


/// Mobile Men
// mobile navigation
.c-header_phone {
    position: relative;
    left:0px;
    top:2px;
    @include media(md) {
        display: none;
    }
}
.c-menu__dis {
    float: right;
    display: block;
    position: relative;
    right: 35px;
    font-family: $hind_bold;
    text-decoration: none;
    color: white;
    text-align: right;
    font-size: 14px;
    margin-top: 10px;
    @include media(md) {
        display: none;
    }
}

.is-sticky {
  position: fixed;
  width: 100%;
  top: 0px;
  margin-top: 0px;
  z-index: 100000;
}

.c-header__mobile {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 50;
    height: auto;
    padding-top: 50px;
    background-color: color('orange');
    margin-top: rem(0px);
    ul {
        position: relative;
        list-style: none;
        margin-top: rem(10px);
        li {
            text-align: center;
            .sub-menu {
                position: relative;
                left: rem(0px);
                margin-left: rem(0px);
                display: block;
                margin-top: 0px;
                display: none;
                @include media(sm) {
                    margin-top: neg(rem(10px));
                }
                @include media(lg) {
                    margin-top: rem(0px);
                }
                li {
                    padding: rem(0px) rem(0px);
                    padding-left: rem(10px);
                }
            }
            a {
                text-decoration: none;
                font-size: rem(20px);
                color: white;
                line-height: rem(40px);
                text-transform: uppercase;
                &:hover {
                    color: white;
                }
            }
        }
    }
}

.c-mobile__phone {
    display: block;
    position: absolute;
    margin-top:5px;
    z-index: 10;
    @include media(md) {
        display: none;
    }
}
