///
// Custom fonts
///

// Example
// @font-face {
//  font-family: 'questrialregular';
//  src: url('../../dist/fonts/questrial-regular-webfont.woff2') format('woff2'),
//       url('../../dist/fonts/questrial-regular-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
// }

@font-face {
font-family: 'Hind-Bold';
src: url('../../dist/fonts/Hind-Bold.ttf');
font-weight: normal;
font-style: normal;
 }

 @font-face {
 font-family: 'Hind-Light';
 src: url('../../dist/fonts/Hind-Light.ttf');
 font-weight: normal;
 font-style: normal;
  }

  @font-face {
  font-family: 'Hind-Medium';
  src: url('../../dist/fonts/Hind-Medium.ttf');
  font-weight: normal;
  font-style: normal;
   }

   @font-face {
   font-family: 'Hind-Regular';
   src: url('../../dist/fonts/Hind-Regular.ttf');
   font-weight: normal;
   font-style: normal;
    }

    @font-face {
    font-family: 'Hind-Semibold';
    src: url('../../dist/fonts/Hind-Semibold.ttf');
    font-weight: normal;
    font-style: normal;
     }

     @font-face {
     font-family: 'Kalam-Regular';
     src: url('../../dist/fonts/Kalam-Regular.ttf');
     font-weight: normal;
     font-style: normal;
      }

      @font-face {
      font-family: 'Kalam-Light';
      src: url('../../dist/fonts/Kalam-Light.ttf');
      font-weight: normal;
      font-style: normal;
       }

       @font-face {
       font-family: 'Kalam-Bold';
       src: url('../../dist/fonts/Kalam-Bold.ttf');
       font-weight: normal;
       font-style: normal;
        }
