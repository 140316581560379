.c-contact__left {
    background-color: color('red');
    .o-col__inner {
        width: 100%;
        margin-right: auto;
        min-height: 500px;
        height:auto;
        padding-top: 30px;
        @include media(md) {
            width: 100%;
            margin-left: 5px;
        }
        @include media(lg) {
            width: 400px;
            margin-left: auto;
            margin-right: 50px;
        }
        h4 {
            color: white;
            text-align: center;
        }
        p,a {
            color: white;
            text-align: center;
            font-family: $hind_light;
            font-weight: normal;
        }
        .c-btn {
            display: table;
            margin: 0px auto;
            font-size: 26px;
            font-family: $hind_bold;
        }
    }
}
.c-contact__right {
    height: 100%;
    margin-left: 0px;
    margin-right: auto;
    background-color: color('blue');
    #map {
        margin-left: 0px;
        margin-right: auto;
        width: 100%;
        height: 500px;
        @include media(md) {
            width: 104%;
            margin-left: -15px;
        }
    }
}
