.c-content__hero {
  position: relative;
  left: 0px;
  .soliloquy-image {
    width: 100%;
    position: relative;
    left: -130px;
    min-width: 600px;
    @include media(xs) {
      left: -120px;
      min-width: 850px;
    }
    @include media(sm) {
      left:00px;
    }
  }
}

.page-template-template-landing-page,
.page-template-template-home-for-sale {
  .c-hero {
    img {
      filter: brightness(50%);
    }
  }
}
