*, *:before, *:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}
 hr {
     border-top: none;
     border-color: color('orange');
     margin: 30px 0px;
 }
