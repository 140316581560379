body {
    margin: 0;
    font-size: $base-font-size;
    font-family: $base-font-family;
    line-height: $base-line-height;
    color: $base-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}
html {
    overflow-x: hidden;
}
