.c-weather {
    background-image: url("/app/themes/sage/dist/images/header-woodgrain.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    margin-top: 0px;
    padding: 60px 0px;
}

.c-weather__content {
    .o-col--12\@xs {
        display: none;

        @include media(sm){
            &:nth-child(1),
            &:nth-child(2){
                display: block;
            }
        }
        @include media(md){
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3){
                display: block;
            }
        }
        @include media(lg){
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4){
                display: block;
            }
        }
        @include media(xl){
            display: block;

        }
        &:first-child{
            display: block;
        }

    }

    .c-weather__day {
        .wu-day-title {
            color: white;
          font-size: rem(36px);
            text-transform: uppercase;
            font-family: $kalam_regular;
            line-height: 24px;
        }
    }
    .wu-cond-text, .wu-cond-pop {
        color: white;
        font-size: 20px;
        font-family: $hind_light;
    }
}
.wu-cond-pop {
    font-weight: 400;
    display: inline-block;
    background: url('/app/themes/sage/dist/images/drop.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    padding-left: rem(40px);
    margin-left: rem(5px);
    min-height: rem(40px);
    height: rem(40px);
    z-index: 100;
    top: rem(0px);
    position: relative;
    width: rem(100px);
}
.wu-cond-highlow {
    span {
        color: white;
        font-size: rem(20px);
        font-family: $hind_light;
    }
}
.wu-icon {
    margin-top: 40px;
}
.wu-copyright {
    display: none;
}
