///
// Breakpoints
///
$breakpoints: (
    xs: 480px,  // Extra Small devices (landscape phones, 480px and up)
    sm: 768px,  // Small devices (tablets, 768px and up)
    md: 1024px, // Medium devices (desktops, 1024px and up)
    lg: 1200px, // Large devices (desktops, 1200px and up)
    xl: 1500px  // Extra Lage devices (desktops, 1500px and up)
);

///
// Base global defaults
///
$base-columns:      12;
$base-gutter:       30px;
$base-font-size:    16px;
$base-font-family:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-line-height:  1.5;
$base-font-color:   #000000;


//
///Font family Hind
//
$hind_bold:  "Hind-Bold";
$hind_light:  "Hind-Light";
$hind_medium:  "Hind-Medium";
$hind_regular:  "Hind-Regular";
$hind_semibold:  "Hind-semibold";
//
///Font family Kalam
//
$kalam_regular:  "Kalam-Regular";
$kalam_light:  "Kalam-Light";
$kalam_bold:  "Kalam-Bold";

//
///Font family
//// Font sizes
$font-sizes: (
'p': (
    'initial':  18px,
    'sm':       20px
),
    'h1': (
        'initial':  40px,
        'sm':       60px
    ),
    'h2': (
        'initial':  30px,
        'sm':       50px
    ),
    'h3': (
        'initial':  34px,
        'sm':       40px
    ),
    'h4': (
        'initial':  26px,
        'sm':       30px
    ),
    'h5':           26px,
    'h6':           22px
);

///
// Column offsets
//
// Will enable the creation of column offset classes
///
$column-offsets: false;

///
// Debug mode
//
// In order to use the debug mode you need to set the below variable to true
// and you also need to set the THEME_DEBUG constant in the wp-config to true
///
$debug: false;

///
// Colors Map for @function color();
///
$colors: (
    'white': (
        'default':      #ffffff
    ),
    'grey': (
        'default':      #4f6465,
        'medium':       #435051,
    ),
    'bigrigmedia': (
        'orange':       #f6921e,
        'grey':         #4d4d4d
    ),
    'orange': (
        'default':      #FFA000,
        'medium':       #d08808
    ),
    'brown': (
        'default':      #4b2c1e,
        'medium':      #382218,
    ),
    'blue': (
        'default':      #42908e,
        'medium':       #1b3b3a,
    ),
    'red': (
        'default':      #a5372d,
    ),
    'beige': (
        'default':      #faf4e5,
    ),
);

///
// Alerts Map for @function alert();
///
$alerts: (
    'success': (
        'default':  #dff0d8,
        'message':  #3c763d,
        'border':   #d6e9c6
    ),
    'info': (
        'default':  #d9edf7,
        'message':  #31708f,
        'border':   #bce8f1
    ),
    'warning': (
        'default':  #fcf8e3,
        'message':  #8a6d3b,
        'border':   #faf2cc
    ),
    'error': (
        'default':  #f2dede,
        'message':  #a94442,
        'border':   #ebcccc
    )
);

///
// Social Networks Map for @function social();
///
$social-networks: (
    facebook:   #3b5998,
    twitter:    #00aced,
    googleplus: #dd4b39,
    linkedin:   #007bb6,
    youtube:    #bb0000,
    instagram:  #517fa4,
    pinterest:  #cb2027,
    flickr:     #ff0084,
    tumblr:     #32506d,
    foursquare: #0072b1,
    dribble:    #ea4c89,
    vine:       #00bf8f
);

///
// Z-index Map for @function z();
///
$z-index: (
    debug: 1
);
