.c-btn {
    display:table;
    padding: 0px 20px;
    padding-top: 5px;
    line-height: 36px;
    color: color('blue');
    text-decoration: none;
    font-size: rem(36px);
    font-family: $hind_bold;
    transition: background-color 1s ease;
    letter-spacing: 1px;
    color: white;
    border: 4px solid white;
    &:hover {
        color: white;
    }
}
.c-btn--green {
    background-color: color('blue');
    &:hover {
        background-color: color('blue', 'medium');
    }
}
.c-btn--orange {
    background-color: color('orange');
    &:hover {
        background-color: color('orange', 'medium');
    }
}
.c-btn--grey {
    background-color: color('grey');
    &:hover {
        background-color: color('grey', 'medium');
    }
}
.c-btn--brown {
    background-color: color('brown');
    &:hover {
        background-color: color('brown', 'medium');
    }
}

.c-btn--red {
    background-color: color('red');
    color: white;
    &:hover {
        background-color: color('orange');
    }
}
